@import url(https://fonts.googleapis.com/css?family=Numans);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Made with love by Mutiullah Samim*/
html,
body {
    background-color: #f7f7f7;
    height: 100%;
    font-family: "Numans", sans-serif;
}

.success-message {
    position: absolute;
    top: 2rem;
    width: 100%;
}

.tooltip-custom .tooltiptext {
    visibility: hidden;
    width: 18rem;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 5rem;
}

.tooltip-custom:hover .tooltiptext {
    visibility: visible;
}

.cursor-pointer {
    cursor: pointer;
}

.name-init-select {
    width: 30% !important;
}

.fa-question-circle {
    color: #42b0f5;
}

#notfound {
    position: relative;
    height: 100vh;
}

#notfound .notfound {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.notfound {
    max-width: 460px;
    width: 100%;
    text-align: center;
    line-height: 1.4;
}

.notfound .notfound-404 {
    position: relative;
    width: 180px;
    height: 180px;
    margin: 0px auto 50px;
}

.notfound .notfound-404>div:first-child {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #ffa200;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    border: 5px dashed #000;
    border-radius: 5px;
}

.notfound .notfound-404>div:first-child:before {
    content: "";
    position: absolute;
    left: -5px;
    right: -5px;
    bottom: -5px;
    top: -5px;
    box-shadow: 0px 0px 0px 5px rgba(0, 0, 0, 0.1) inset;
    border-radius: 5px;
}

.notfound .notfound-404 {
    font-family: "Cabin", sans-serif;
    color: #000;
    font-weight: 700;
    margin: 0;
    font-size: 90px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    left: 50%;
    text-align: center;
    height: 40px;
    line-height: 40px;
}

.browserTitle {
    font-family: "Cabin", sans-serif;
    font-size: 5em;
    white-space:normal;
    word-break: break-all;
    color: grey;
    text-shadow: -1px -1px 0px rgba(255, 255, 255, 0.3), 1px 1px 0px 
    rgba(0, 0, 0, 0.8);
    text-align:center;
    width:100%;
}


.burgerbtn{
    position: fixed;
    top: 5%;
    left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 5;
}

button div {
    width: 2rem;
    height: 0.25rem;
    background: black;
    border-radius: 10px;
    /* transition: all 0.3s linear; */
    position: relative;
    /* transform-origin: 1px; */
  }

img {
    width: 80%;
    height: 80%;
}

.mobileTitle {
    font-family: "Cabin", sans-serif;
    font-size: 2em;
    white-space:normal;
    word-break: break-all;
    color: grey;
    text-shadow: -1px -1px 0px rgba(255, 255, 255, 0.3), 1px 1px 0px 
    rgba(0, 0, 0, 0.8);
    text-align:center;
    width:100%;
}

.mobileLandingTitle {
    border-radius: 5px;
    font-family: "Cabin", sans-serif;
    font-size: 2em;
    white-space:normal;
    word-break: break-all;
    margin-top:30%;
    margin-left: 15%;
    padding: 4%;
    color: white;
    text-shadow: -1px -1px 0px rgb(255, 255, 255), 1px 1px 0px 
    rgba(0, 0, 0, 0.8);
    text-align:center;
    background-image: linear-gradient(45deg, #42b0f5 30%, #1b1d4d 90%);
    width:70%;
}

ol img {
    border: 2px groove #0092da;
    border-radius: 5px;
    margin: 2%;
}

.listTop {
    cursor: pointer;
    margin-top: 8%;
}

.sidediv {
    cursor: pointer;
}

ul li {
    margin: 2%;
    list-style-type: none;
    color: white;
}

.videoContainer{
    z-index: 1;
    margin-left: 10%;
    margin-right: 10%;
    
}


h3:hover,
li h2:hover, 
.navhead:hover {
    color: #44b1e7;
    text-shadow: -0.1px -0.1px 0 rgba(83, 87, 88, 0.89), 0.1px -0.1px 0 rgb(83, 87, 88, 0.89), -0.1px 0.1px 0 rgb(83, 87, 88, 0.89), 0.1px 0.1px 0 rgb(83, 87, 88, 0.89);
}

.spacebottom {
    padding-bottom: 5%;
    margin-left: 20%;
    z-index: 1;
}

a:visited {
    text-decoration: none;
}

.center {
    margin: 0;
    position: absolute;
    top: 30%;
    left: 55%;
    margin-right: -50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.sectionHead {
    background-color: #42b0f5;
    color: "white";
    padding: "50px";
    border-radius: "25%";
}

.bg-white {
    background-color: white;
    /* border: #1b1e24;
    border-width: 1px;
    border-style: solid;
    border-radius: 5%; */
}

.video, .video:hover {
    text-decoration: none;
    color: white;
}

.video:visited, .video:focus{
    text-decoration: none;
}

ul li:hover {
    font-style: italic;
    text-decoration: none;

    color: #44b1e7;
    text-shadow: -0.1px -0.1px 0 rgba(83, 87, 88, 0.89), 0.1px -0.1px 0 rgb(83, 87, 88, 0.89), -0.1px 0.1px 0 rgb(83, 87, 88, 0.89), 0.1px 0.1px 0 rgb(83, 87, 88, 0.89);
}

ul li:visited {
    font-style: italic;
    text-decoration: none;
}

ol li {
    margin-top: 1%;
    margin-left: 4%;
}

.indent {
    margin-left: 3%;
}

.notfound h2 {
    font-family: "Cabin", sans-serif;
    font-size: 33px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 7px;
}

.notfound p {
    font-family: "Cabin", sans-serif;
    font-size: 16px;
    color: #000;
    font-weight: 400;
}

.notfound a {
    font-family: "Cabin", sans-serif;
    display: inline-block;
    padding: 10px 25px;
    background-color: #8f8f8f;
    border: none;
    border-radius: 40px;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    text-decoration: none;
    transition: 0.2s all;
}

.notfound a:hover {
    background-color: #2c2c2c;
}

.cst-treatment-and-doctor {
    height: 4rem;
    width: 9.8rem;
    display: flex;
    align-items: center;
}

.container {
    height: 100%;
    align-content: center;
}

.card {
    margin-top: auto;
    margin-bottom: auto;
    width: 400px;
    background-color: rgba(0, 0, 0, 0.5) !important;
}

.social_icon span {
    font-size: 60px;
    margin-left: 10px;
    color: #42b0f5;
}

.social_icon span:hover {
    color: white;
    cursor: pointer;
}

.card-header h3 {
    color: white;
}

.social_icon {
    position: absolute;
    right: 20px;
    top: -45px;
}

.input-group-prepend span {
    width: 50px;
    background-color: #ffc312;
    border: 0 !important;
}

input:focus {
    outline: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
}

.remember {
    color: white;
}

.remember input {
    width: 20px;
    height: 20px;
    margin-left: 15px;
    margin-right: 5px;
}

.login_btn {
    color: white;
    background-color: #42b0f5;
    width: 100px;
}

.login_btn:hover {
    color: black;
    background-color: white;
}

.links {
    color: white;
}

.blue_btn {
    color: white;
    background-color: #42b0f5;
    width: 100px;
}

.links a {
    margin-left: 4px;
}

.required-boder {
    border-color: red !important;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.radio-item {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    margin: 10px 0 0;
}

.radio-item input[type="radio"] {
    display: none;
}

.radio-item label {
    color: #666;
    font-weight: normal;
}

.radio-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid #004c97;
    background-color: transparent;
}

.radio-item input[type="radio"]:checked+label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 10px;
    content: " ";
    display: block;
    background: #004c97;
}


/* LAYA FORM CSS COMPONENETS /*
/* membership-number */

.laya-hospital-label {
    position: absolute;
    top: 420px;
    left: 710px;
    font-size: 22px;
    font-family: "Courier New", Courier, monospace;
}

.laya-invoice-number {
    position: absolute;
    top: 450px;
    left: 710px;
    font-size: 22px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s1-membership-number {
    position: absolute;
    top: 661px;
    left: 193px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s1-mrn-number {
    position: absolute;
    top: 661px;
    left: 591px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s1-title {
    position: absolute;
    top: 735px;
    left: 105px;
    font-optical-sizing: auto;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s1-surname {
    position: absolute;
    top: 735px;
    left: 340px;
    font-optical-sizing: auto;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s1-forename {
    position: absolute;
    top: 735px;
    left: 685px;
    font-optical-sizing: auto;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s1-dob {
    position: absolute;
    top: 761px;
    left: 286px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s1-address {
    position: absolute;
    top: 882px;
    left: 66px;
    font-optical-sizing: auto;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s1-phone-number {
    position: absolute;
    top: 824px;
    left: 680px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s1-accident-treatment-yes {
    position: absolute;
    top: 910px;
    left: 795px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s1-accident-treatment-no {
    position: absolute;
    top: 910px;
    left: 876px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s1-private-patient-yes {
    position: absolute;
    top: 970px;
    left: 795px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s1-private-patient-no {
    position: absolute;
    top: 970px;
    left: 876px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s2-hospital-name {
    position: absolute;
    top: 1141px;
    left: 66px;
    font-optical-sizing: auto;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s2-admission-date {
    position: absolute;
    top: 1096px;
    left: 846px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s3-symptoms-date {
    position: absolute;
    top: 1221px;
    left: 540px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s3-consult-with-doctor-date {
    position: absolute;
    top: 1283px;
    left: 695px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s3-yes {
    position: absolute;
    top: 1338px;
    left: 858px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s3-no {
    position: absolute;
    top: 1338px;
    left: 935px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s3-date {
    position: absolute;
    top: 1390px;
    left: 294px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s4-date {
    position: absolute;
    top: 1524px;
    left: 834px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s4-name-dr-attended {
    position: absolute;
    top: 1548px;
    left: 280px;
    font-optical-sizing: auto;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s4-doctors-address {
    position: absolute;
    top: 1592px;
    left: 195px;
    font-optical-sizing: auto;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s5-date {
    position: absolute;
    top: 1962px;
    left: 365px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s5-where-injury-accident {
    position: absolute;
    top: 2029px;
    left: 340px;
    font-optical-sizing: auto;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s5-how-injury-accident {
    position: absolute;
    top: 2099px;
    left: 290px;
    font-optical-sizing: auto;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s5-fault-other-party-yes {
    position: absolute;
    top: 2149px;
    left: 845px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s5-fault-other-party-no {
    position: absolute;
    top: 2149px;
    left: 927px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s5-responsible-party-contact-details {
    position: absolute;
    top: 2240px;
    left: 65px;
    font-optical-sizing: auto;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s5-address-of-insurer {
    position: absolute;
    top: 2320px;
    left: 445px;
    font-optical-sizing: auto;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s5-claim-solicitor-yes {
    position: absolute;
    top: 2395px;
    left: 845px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s5-claim-solicitor-no {
    position: absolute;
    top: 2395px;
    left: 927px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s5-piab-yes {
    position: absolute;
    top: 2445px;
    left: 845px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s5-piab-no {
    position: absolute;
    top: 2445px;
    left: 927px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s5-address-of-solicitor {
    position: absolute;
    top: 2540px;
    left: 65px;
    font-optical-sizing: auto;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s6-print-name {
    position: absolute;
    top: 3271px;
    left: 190px;
    font-optical-sizing: auto;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s6-patient-signature {
    position: absolute;
    top: 3310px;
    left: 100px;
    font-optical-sizing: auto;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s6-signature-date {
    position: absolute;
    top: 3328px;
    left: 828px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s7-membership-number {
    position: absolute;
    top: 3657px;
    left: 206px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s7-mrn-number {
    position: absolute;
    top: 3657px;
    left: 610px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s7-hospital-name {
    position: absolute;
    top: 3763px;
    left: 169px;
    font-size: 42px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s7-admit-date {
    position: absolute;
    top: 3817px;
    left: 326px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s7-admit-ts {
    position: absolute;
    top: 3817px;
    left: 689px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s7-discharge-date {
    position: absolute;
    top: 3868px;
    left: 326px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s7-discharge-ts {
    position: absolute;
    top: 3868px;
    left: 689px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s7-aande {
    position: absolute;
    top: 4622px;
    left: 71px;
    font-size: 42px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-emergency {
    position: absolute;
    top: 4924px;
    left: 601px;
    font-size: 42px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-elective {
    position: absolute;
    top: 4924px;
    left: 711px;
    font-size: 42px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-accident-yes {
    position: absolute;
    top: 4977px;
    left: 697px;
    font-size: 42px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-accident-no {
    position: absolute;
    top: 4977px;
    left: 775px;
    font-size: 42px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-explain-injury-accident {
    position: absolute;
    top: 5032px;
    left: 175px;
    font-optical-sizing: auto;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-where-injury-accident {
    position: absolute;
    top: 5182px;
    left: 240px;
    font-optical-sizing: auto;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-how-injury-accident {
    position: absolute;
    top: 5152px;
    left: 240px;
    font-optical-sizing: auto;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-date-saw-patient {
    position: absolute;
    top: 5451px;
    left: 486px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-duration-of-symptoms-days {
    position: absolute;
    top: 5502px;
    left: 406px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-duration-of-symptoms-weeks {
    position: absolute;
    top: 5502px;
    left: 540px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-duration-of-symptoms-months {
    position: absolute;
    top: 5502px;
    left: 685px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-duration-of-symptoms-years {
    position: absolute;
    top: 5502px;
    left: 806px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-previous-eposide-related-symptoms-yes {
    position: absolute;
    top: 5553px;
    left: 846px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-previous-eposide-related-symptoms-no {
    position: absolute;
    top: 5553px;
    left: 919px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-referred-by-gp {
    position: absolute;
    top: 5782px;
    left: 696px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-referred-by-consultant {
    position: absolute;
    top: 5782px;
    left: 838px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-special-medication {
    position: absolute;
    top: 5860px;
    left: 69px;
    font-optical-sizing: auto;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-primary-diagnosis {
    position: absolute;
    top: 6018px;
    left: 69px;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-secondary-diagnosis {
    position: absolute;
    top: 6118px;
    left: 69px;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-other-diagnosis {
    position: absolute;
    top: 6218px;
    left: 69px;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-membership-number {
    position: absolute;
    top: 7189px;
    left: 206px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s8-mrn-number {
    position: absolute;
    top: 7189px;
    left: 610px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}


/*Section 9*/

.laya-s9-date-of-scan {
    position: absolute;
    top: 9294px;
    left: 282px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s9-facility-name {
    position: absolute;
    top: 9313px;
    left: 800px;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s9-procedure-code {
    position: absolute;
    top: 9363px;
    left: 280px;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s9-anatomical-site-examined {
    position: absolute;
    top: 9425px;
    left: 69px;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s9-consultant-overall-charge {
    position: absolute;
    top: 9498px;
    left: 340px;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s9-clinical-indicator {
    position: absolute;
    top: 9404px;
    left: 820px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s9-consultant-code {
    position: absolute;
    top: 9476px;
    left: 820px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s9-date-scan-signed {
    position: absolute;
    top: 9534px;
    left: 854px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s9-consultant-signature {
    position: absolute;
    top: 9515px;
    left: 210px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}


/* Section 10 */

.laya-s10-name-of-consultant {
    position: absolute;
    top: 9729px;
    left: 215px;
    font-size: 1em;
    font-family: "Courier New", Courier, monospace;
}

.laya-s10-consultant-signature {
    position: absolute;
    top: 9745px;
    left: 260px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s10-laya-consultant-code {
    position: absolute;
    top: 9706px;
    left: 932px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.laya-s10-date-scan-signed {
    position: absolute;
    top: 9786px;
    left: 850px;
    font-size: 52px;
    font-family: "Courier New", Courier, monospace;
}

.page {
    width: 21cm;
    min-height: 29.7cm;
    padding: 2cm;
    margin: 1cm auto;
    border: 1px #d3d3d3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.subpage {
    padding: 1cm;
    border: 5px red solid;
    height: 256mm;
    outline: 2cm #ffeaea solid;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@page {
    size: A4;
    margin: 0;
}

@media print {
    html,
    body {
        width: 210mm;
        height: 297mm;
    }
}
